import axios, { AxiosError, AxiosHeaders } from "axios";
import { AuthResponse, ApiSuccess, TokenPair } from "../types/apiTypes";
import { ResponsibleUserService } from "./ResponsibleUserService";
import { TimeUtil } from "../utils/TimeUtil";
import PKCEUtils from "../utils/PKCEUtils";

const RESPONSIBLE_USER_HEADER = "X-Responsible-User-Id";

export const API_PATHS = {
  getCalendarDates: "/retrieve-planning-dates",
  initiateNexusPlan: "/start-nexus-data-extraction",
  getNexusFilters: "/retrieve-nexus-filters",
  getActiveNexusFilters: "/retrieve-active-nexus-filters",
  getNexusPlan: "/gantt-chart-data",
  getCitizenKPIList: "/todo:addhere",
  retrieveKPIs: "/kpi-dashboard-data",
  retrievePondooStatus: "/jobs/state",
  startOptimization: "/start-optimization",
  stopOptimization: "/stop-optimization",
  postPreferrence: "/employee-favorability",
  nexusLanding: "/nexus-landing",
  createOrUpdateNexusFilter: "/get-or-update-nexus-filter",
  postNexusPlan: "/send-plan-to-nexus",
  activeJobHistories: "/active-job-histories",
  websocketJobs: "/job-message-history",
  getLatestOptimizationJobs: "/get-optimization-jobs",
  postLockShiftSchedule: "/lock-shift-schedule",
  getFullPlanViewState: "/get-full-plan-state", // DEPRECATED
  getFullPlanViewStateV2: "/get-full-plan-state-v2",
  errorLogging: "/error-log",
} as const;

const api = axios.create({
  baseURL: window._env_.REACT_APP_API_URL,
});

class TokenRefreshQueue {
  private isRefreshing = false;
  private refreshSubscribers: ((token: string) => void)[] = [];

  public onTokenRefreshed(token: string) {
    this.refreshSubscribers.forEach((callback) => callback(token));
    this.refreshSubscribers = [];
  }

  public addSubscriber(callback: (token: string) => void) {
    this.refreshSubscribers.push(callback);
  }

  public isCurrentlyRefreshing() {
    return this.isRefreshing;
  }

  public setRefreshing(value: boolean) {
    this.isRefreshing = value;
  }
}

const tokenQueue = new TokenRefreshQueue();

export const setupAuthInterceptor = (bearerToken: string) => {
  // Clear existing interceptors if any
  api.interceptors.request.clear();

  api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${bearerToken}`;

    const responsibleUserId =
      ResponsibleUserService.getInstance().getResponsibleUserId();
    if (responsibleUserId) {
      config.headers[RESPONSIBLE_USER_HEADER] = responsibleUserId;
    }

    return config;
  });
};

export const setupRefreshTokenInterceptor = (signalTokenSet: () => void) => {
  api.interceptors.response.clear();
  api.interceptors.response.use(
    (response) => {
      const xResponsibleUserId = (response.headers as AxiosHeaders).get(
        RESPONSIBLE_USER_HEADER,
      );
      if (xResponsibleUserId) {
        ResponsibleUserService.getInstance().setResponsibleUserId(
          xResponsibleUserId as string,
        );
      }

      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!tokenQueue.isCurrentlyRefreshing()) {
          tokenQueue.setRefreshing(true);

          try {
            const refreshToken = localStorage.getItem("refresh_token");
            if (!refreshToken) {
              AuthService.redirectToLogin();
              return Promise.reject(error);
            }

            const tokens = await AuthService.refreshConcreteToken(refreshToken);
            if (!tokens) {
              AuthService.redirectToLogin();
              return Promise.reject(error);
            }

            localStorage.setItem("access_token", tokens.accessToken);
            localStorage.setItem("refresh_token", tokens.refreshToken);

            signalTokenSet();
            setupAuthInterceptor(tokens.accessToken);

            tokenQueue.onTokenRefreshed(tokens.accessToken);
            tokenQueue.setRefreshing(false);

            originalRequest.headers["Authorization"] =
              `Bearer ${tokens.accessToken}`;
            return api(originalRequest);
          } catch (refreshError) {
            tokenQueue.setRefreshing(false);
            console.error(refreshError);
            localStorage.clear();
            AuthService.redirectToLogin();
            return Promise.reject(refreshError);
          }
        }

        // If refresh is already in progress, wait for the new token
        return new Promise((resolve) => {
          tokenQueue.addSubscriber((token: string) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    },
  );
};

export class AuthService {
  static tokenURL = window._env_.REACT_APP_TOKEN_URL;
  static loginURL = window._env_.REACT_APP_LOGIN_URI;
  static clientId = window._env_.REACT_APP_CLIENT_ID;
  static clientSecret = window._env_.REACT_APP_CLIENT_SECRET;

  static headers: Record<string, string> = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  static redirectToLogin = () => (window.location.href = this.loginURL);

  static refreshConcreteToken = async (
    refreshToken: string,
  ): Promise<TokenPair | undefined> => {
    const params = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken as string,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    });

    // Call token refresh endpoint using fetch, to avoid triggering an infinite loop in the Axios intercept handler
    const response = await fetch(this.tokenURL, {
      method: "POST",
      headers: this.headers,
      body: params,
    });

    if (response.status === 200) {
      const data = await response.json();
      return this.setAuthResponseInLocalStorageAndReturnToken({
        success: true,
        data: data,
      });
    } else {
      console.error("Could not retrieve an authorization token");
      this.redirectToLogin();
    }
  };

  /**
   * Redirects the user to an appropriate auth challenge with Nexus, and ensures that filterId and date are present
   * in the redirect uri.
   * @param filterId
   * @param date
   * @param filterName
   */
  static performLogin = async (
    filterId: string,
    date: string,
    filterName: string,
    resourceIds: string | null,
  ) => {
    let pkce = await PKCEUtils.generatePKCE();

    let code_verifier = pkce[0];
    let code_challenge = pkce[1];

    localStorage.setItem("code_verifier", code_verifier);

    const authUrl = new URL(window._env_.REACT_APP_AUTH_URL);

    const redirectUri = this.getNexusAuthReturnUrl();

    redirectUri.searchParams.append("date", date);
    redirectUri.searchParams.append("filterId", filterId);
    redirectUri.searchParams.append("filterName", filterName);
    redirectUri.searchParams.append("resourceIds", resourceIds ?? "");

    localStorage.setItem("redirectUri", redirectUri.toString());

    authUrl.searchParams.append("client_id", window._env_.REACT_APP_CLIENT_ID);
    authUrl.searchParams.append("code_challenge", code_challenge);
    authUrl.searchParams.append("code_challenge_method", "S256");
    authUrl.searchParams.append("response_type", "code");
    authUrl.searchParams.append("redirect_uri", redirectUri.toString());

    window.location.href = authUrl.toString();
  };

  public static exchangeCodeForToken = async (): Promise<
    TokenPair | undefined
  > => {
    const redirectUri = localStorage.getItem("redirectUri");
    if (!redirectUri)
      return Promise.reject(
        "Attempted to retrieve a token without a set redirectUri",
      );

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const codeVerifier = localStorage.getItem("code_verifier");

    if (!code || !codeVerifier) {
      return Promise.reject("Code or code verifier couldn't be found");
    }

    const body = new URLSearchParams({
      grant_type: "authorization_code",
      client_id: window._env_.REACT_APP_CLIENT_ID,
      client_secret: window._env_.REACT_APP_CLIENT_SECRET,
      code: code!,
      code_verifier: codeVerifier!,
      redirect_uri: redirectUri!,
    });

    const headers: Record<string, string> = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const authTokenResponse = await api.post<AuthResponse>(
        window._env_.REACT_APP_TOKEN_URL,
        body,
        headers,
      );

      if (authTokenResponse.status === 200) {
        return this.setAuthResponseInLocalStorageAndReturnToken({
          success: true,
          data: authTokenResponse.data,
        });
      }
    } catch (error) {
      console.error(error);
      const errorPayload =
        error instanceof AxiosError
          ? JSON.stringify(error.toJSON())
          : JSON.stringify({ message: (error as Error).message });
      await api.post(API_PATHS.errorLogging, errorPayload);
    }
  };

  private static getNexusAuthReturnUrl = (): URL =>
    new URL(
      `${window.location.protocol}//${window.location.host}/nexus-landing`,
    );

  private static setAuthResponseInLocalStorageAndReturnToken = (
    authResponse: ApiSuccess<AuthResponse>,
  ): TokenPair => {
    const { access_token, expires_in, refresh_token, refresh_expires_in } =
      authResponse.data;

    localStorage.setItem("last_set_time", TimeUtil.dateTimeNowString());
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("access_token_expires_in", expires_in);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("refresh_expires_in", refresh_expires_in);

    return { accessToken: access_token, refreshToken: refresh_token };
  };
}

export default api;
