import { useAppDispatch, useAppSelector } from "../store/hooks";
import WorkTimeCard from "../components/kpiCards/WorkTime";
import CitizenServiceCard from "../components/kpiCards/CitizenServiceCard";
import KeyNumbersCard from "../components/kpiCards/KeyNumbersCard";
import PlannedTimeCard from "../components/kpiCards/PlannedTimeCard";
import { Spinner } from "../components/UI/Spinner";
import PondooPlanStatusBar from "../components/PondooPlanStatusBar";
import { useSearchParams } from "react-router-dom";
import { useSolutionOverviewStatus } from "../store/realTimeUpdates/hooks";
import { useEffect, useState } from "react";
import { getKPICollection } from "../store/KPICollection/action";

export default function KPIPage() {
  const dispatch = useAppDispatch();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { kpiData, comepareWithNexus: compareWithNexus } = useAppSelector(
    (state) => state.kpiCollectionSlice,
  );
  const { error } = useAppSelector((state) => state.ganttChartSlice);
  const [searchParams, _] = useSearchParams();
  const date = searchParams.get("date");
  const filter = searchParams.get("filter");

  const overviewState = useSolutionOverviewStatus({
    filterId: (filter && parseInt(filter)) || undefined,
    date: date || undefined,
  });

  useEffect(() => {
    setShowSpinner(
      (overviewState.nexusStatus === "loading" ||
        overviewState.pondooStatus === "loading") &&
        !kpiData?.pondooDashboard,
    );
  }, [overviewState, kpiData]);
  const cannotRender = !kpiData || overviewState.nexusStatus === "missing";

  useEffect(() => {
    if (cannotRender) {
      if (overviewState.idScenario) {
        dispatch(getKPICollection({ idScenario: overviewState.idScenario }));
      }
    }
  }, [overviewState]);

  return (
    <article className="flex w-full gap-[var(--spacing-sm)]">
      {showSpinner && <Spinner fullSize />}
      {(cannotRender || !overviewState.idScenario) && !showSpinner && (
        <div className="flex flex-col gap-[10px] items-center justify-center h-full w-full">
          {overviewState.nexusStatus === "error" ? (
            <p style={{ color: "var(--col-dirt-rose-dark)" }}>{error}</p>
          ) : (
            <p>
              <b>Vi mangler en plan for i dag.</b> <br />
              Gå til Nexus for at sende data fra kalenderen.
            </p>
          )}
        </div>
      )}
      {kpiData && kpiData.pondooDashboard && (
        <>
          <div className="flex flex-col w-[80%] gap-[var(--spacing-sm)]">
            <>
              <CitizenServiceCard
                kpiList={kpiData.pondooDashboard.plan}
                compare={compareWithNexus}
                compareKpiList={kpiData.nexusDashboard.plan}
              />
              <div className="flex gap-[var(--spacing-sm)] h-[389px]">
                <>
                  <KeyNumbersCard
                    kpiList={kpiData.pondooDashboard.otherKpi}
                    compare={compareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.otherKpi}
                  />
                  <PlannedTimeCard
                    kpiList={kpiData.pondooDashboard.plannedTime}
                    compare={compareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.plannedTime}
                  />
                </>
              </div>
            </>
          </div>
          <WorkTimeCard
            kpiList={kpiData.pondooDashboard.workTime}
            compare={compareWithNexus}
            compareKpiList={kpiData.nexusDashboard.workTime}
          />
        </>
      )}
      <PondooPlanStatusBar />
    </article>
  );
}
