import MainContentWrapper from "../components/UI/pageStructure/MainContentWrapper";
import GanttChartTabBar from "../components/ganttChart/filtering/GanttChartTabBar";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import VerticalGanttWrapper from "../components/ganttChart/VerticalGanttWrapper";
import { getGanttChartData } from "../store/ganttChart/action";
import Drawer from "../components/drawer/Drawer";
import DrawerContent from "../components/drawer/DrawerContent";
import PondooPlanStatusBar from "../components/PondooPlanStatusBar";
import SearchBar from "../components/search/SearchBar";
import ZoomComponent from "../components/ganttChart/ZoomComponent";
import {
  PlanViewKey,
  useCreateViewKey,
  useSolutionPlanView,
} from "../store/realTimeUpdates/hooks";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { getKPICollection } from "../store/KPICollection/action";
import { Spinner } from "../components/UI/Spinner";
import { setHideSpinner, setShowSpinner } from "../store/ganttChart";
import comparisonUtil from "../utils/comparisonUtil";

export default function GanttChartPage() {
  const dispatch = useAppDispatch();
  const { isDrawerOpen } = useAppSelector((state) => state.modalSlice);
  const { ganttChartData, showSpinner, error, selectedGanttChartTab } =
    useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange } = useAppSelector((state) => state.timelineSlice);
  const { planningDates } = useAppSelector((state) => state.nexusFiltersSlice);

  const fetchData = () => {
    lastFetchRef.current = { ...viewKey, selectedGanttChartTab };
    dispatch(getGanttChartData(viewKey));
    dispatch(getKPICollection(planViewState));
  };

  const [searchParams, _] = useSearchParams();
  const date = searchParams.get("date");
  const filter = searchParams.get("filter");

  /**
   * Dynamic display state given the user's selection of filter and date
   */
  const viewKey: PlanViewKey = useCreateViewKey({
    selectedGanttChartTab,
    filter,
    date,
  });

  const planViewState = useSolutionPlanView(viewKey);
  const lastFetchRef = useRef<
    PlanViewKey & { selectedGanttChartTab: string }
  >();

  useEffect(() => {
    if (!planningDates) return;

    if (planViewState.status === "loading") {
      dispatch(setShowSpinner());
      return;
    }

    if (["missing", "error"].includes(planViewState.status)) {
      dispatch(setHideSpinner());
    }

    const shouldFetch =
      !!planningDates &&
      !comparisonUtil.deepEqual(lastFetchRef.current || {}, viewKey);

    if (
      (shouldFetch && planViewState.status === "ready") ||
      (planViewState.status === "ready" && !ganttChartData)
    ) {
      fetchData();
    }
  }, [planViewState, planningDates]);

  // Edge case where the global object can get stuck when rapidly switching tabs
  useEffect(() => {
    setTimeout(() => {
      if (
        ganttChartData &&
        !comparisonUtil.deepEqual(viewKey, {
          solutionType:
            selectedGanttChartTab === "Original"
              ? "NexusSolution"
              : "PondooSolution",
          filterId: ganttChartData.idActiveNexusFilter,
          date: ganttChartData.date,
        })
      ) {
        fetchData();
      }
    }, 1000);
  }, []);

  return (
    <>
      <MainContentWrapper classes="flex flex-col">
        {timelineRange && (
          <article className="w-full flex justify-between items-center p-3 min-h-[100px]">
            <GanttChartTabBar />
            {ganttChartData && planViewState.status === "ready" && (
              <div className="flex gap-spacing-md items-center">
                <SearchBar
                  employees={ganttChartData.employees}
                  unplannedTasks={
                    ganttChartData.generalGanttChartInfo.unplannedTasks
                      .workTasks
                  }
                />
              </div>
            )}
          </article>
        )}
        {["missing", "error"].includes(planViewState.status) &&
          !showSpinner && (
            <div className="flex flex-col gap-[10px] items-center justify-center h-full">
              {planViewState.status === "error" ? (
                <p style={{ color: "var(--col-dirt-rose-dark)" }}>
                  {planViewState.errorMessage ?? error}
                </p>
              ) : (
                <p>
                  <b>Vi mangler en plan for i dag.</b> <br />
                  Gå til Nexus for at sende data fra kalenderen.
                </p>
              )}
            </div>
          )}
        {showSpinner && <Spinner fullSize />}
        {ganttChartData && planViewState.status === "ready" && !showSpinner && (
          <VerticalGanttWrapper />
        )}
      </MainContentWrapper>

      {isDrawerOpen && (
        <Drawer>
          <DrawerContent />
        </Drawer>
      )}
      <PondooPlanStatusBar />
      <ZoomComponent />
    </>
  );
}
